module.exports = new Promise(resolve => {
            const dev = "http://www.panthy.dev.com/public//remoteEntry.js"
            const domain = window.location.hostname
  
            if (window["host"]) {
              resolve(window.host)
            }
            else{
              const script = document.createElement('script');
            if (domain === "localhost" || domain === "www.panthy.dev.com" || domain === "127.0.0.1") {
              script.src = dev
            } else {
              script.src = "https://" + domain + "/public/remoteEntry.js"
            }
  
            script.onload = () => {
              script.parentElement.removeChild(script)
              delete window["remotePromises"]["host"]
              const proxy = {
                get: (request) => window.host.get(request),
                init: (arg) => {
                  try {
                    return window.host.init(arg)
                  } catch (e) {
                    console.log("remote container already initialized")
                  }
                }
              }
              
              resolve(proxy)
            }
  
          if(window["remotePromises"] === undefined) {
            window["remotePromises"] = {}
            window["remotePromises"]["host"] = true
          }
          document.head.appendChild(script);
            }
          });